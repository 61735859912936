@import 'styles/variables.scss';

.page404 {
  height: 80vh;
  min-height: 500px;
  background-color: $yellow;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
  @media (max-width: $tablet) {
    padding-left: 16px;
    padding-right: 16px;
  }

  h1 {
    font-size: 100px;
    @media (max-width: $tablet) {
      font-size: 182px;
    }
    @media (min-width: $desktop) {
      font-size: 232px;
    }
  }

  h2 {
    text-align: center;
    @media (min-width: $desktop) {
      font-size: 30px;
    }
  }

  p {
    font-size: 18px;
    @media (min-width: $desktop) {
      font-size: 24px;
    }
  }

  .custom_p {
    text-align: center;
    font-size: 100%;
  }

  a {
    padding: 8px 16px;
    background: $blackFont;
    border-radius: 4px;
    color: $white;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    border: none;
    outline: none;
    min-height: 40px;
    width: fit-content;
    text-decoration: none;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    &:hover{
      background: $lightBlack
    }
    &:focus{
      background: $black-100;
    }
  }
}
